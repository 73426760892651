.section-become-host {
  background: linear-gradient(rgba(0, 0, 0, 0.68) 0%, rgba(0, 0, 0, 0.71) 100%), url('../../assets/images/care1.jpg') center center / cover;
  background-position: center center;

  .section-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin: 40px auto;
    padding: 50px 0;
  }

  .Section-Title {
    font-family: Roboto, sans-serif;
    letter-spacing: 1px;
    font-weight: light;
    font-size: 30px;
    font-size: 30px;
    color: #fafafa;
    text-align: center;
    margin: 0;
    margin-bottom: 8px;
    position: relative;
    z-index: 1;
  }

  .__btn {
    margin: 20px auto;
  }
}