@import './variable';

.default-layout {
  padding-bottom: 30px;
  min-height: 350px;

  .__content {
    margin-top: 32px;

    .sidebar-layout {
      display: flex;

      .verical-nav {
        flex-basis: 20%;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        background-color: #f8f8f8;

        ul {
          list-style: none;
          margin: 0px;
          padding: 0px;

          li {
            border-bottom: 1px solid #e8e8e8;
            margin: 0px;
            display: block;
            padding: 10px;

            a {
              text-decoration: none;
              display: inline-block;
              font-weight: 400;
              font-size: 15px;
              color: #8f8e87;

              &.active {
                font-weight: 700;
                color: $green-theme-color
              }
            }
          }
        }
      }

      @media (min-width: 750px) {
        .dashboard-container {
          margin: 0px 0px 16px;
        }
      }

      .dashboard-form-grp {
        flex-basis: 70%;
      }

      .dashboard-container {
        position: relative;
        background: rgb(255, 255, 255);
        border-radius: 4px;
        border-width: 1px;
        border-style: solid;
        border-color: rgb(223, 226, 231);
        border-image: initial;

        .dashboard-form {
          padding: 16px;
          margin: 10px;
        }
      }
    }
  }
}