@import './variable';

.__btn {
  position: relative;
  display: inline-block;
  width: auto;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  box-sizing: border-box;
  white-space: nowrap;
  font-size: 1em;
  font-family: Roboto, sans-serif;
  cursor: pointer;
  color: white;
  text-decoration: none;
  border-style: solid;
  border-image: initial;
  border-width: 0px;
  padding: 6px 12px;
  border-radius: 22px;
  background: $green-theme-color;
  border-color: transparent;
}