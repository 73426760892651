@import '../../assets/scss/mixins';
@import '../../assets/scss/variable';
@import '../../assets/scss/placeholder';

.section-bg {
  background: center center / cover rgb(251, 249, 238);
  background-position: center center;
  margin-top: 30px;
  padding-bottom: 30px;
}

.section-inner-container {
  padding: 10px;

  ul {
    @include ul-li-clear-list;
    padding: 0px;
    margin: 0px;
    transform: translate3d(0px, 0px, 0px);
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    cursor: inherit;
    box-sizing: border-box;
    touch-action: pan-y pinch-zoom;

    li {
      &:hover {
        box-shadow: rgba(0, 0, 0, 0.1) 0px 6px 9px 0px;
        transform: translateY(-8px);
      }

      &:first-child {
        background: $tile-color1;

        .section-2 {
          background-image: url('../../assets/images/scooty-banner.png');
        }
      }

      &:nth-child(2) {
        background: $tile-color2;

        .section-2 {
          background-image: url('../../assets/images/scooty-banner.png');
        }
      }

      &:nth-child(3) {
        background: $tile-color3;

        .section-2 {
          background-image: url('../../assets/images/scooty-banner.png');
        }
      }

      list-style: none;
      flex-basis: 33%;
      box-sizing: border-box;
      display: inline-block;
      height: auto;
      transform: scale(1);
      transition: transform 0.4s linear 0s;
      vertical-align: top;

      @include media(null, $bp-xsmall) {
        flex-basis: 50%;
      }

    }

  }

}

@media (min-width: 1200px) {
  .section-inner-container {
    width: 1152px;
    margin: 0 auto;
  }
}

@media (min-width: 1000px) {
  .section-title {
    font-size: 24px;
    line-height: 30px;
  }
}

.section-title {
  text-align: center;
  color: inherit;
  font-size: 18px;
  font-family: $font-roboto;
  font-weight: 600;
  line-height: 24px;
  margin: 20px 0px 20px;
}

@media (min-width: 750px) {
  .tile-card {
    min-height: 256px;
  }
}

.tile-card {
  display: flex;
  color: rgb(255, 250, 209);
  cursor: pointer;
  height: 100%;
  min-height: 200px;
  border-radius: 4px;
  overflow: hidden;
  transition: transform 0.3s ease-in-out 0s;

  .section-1 {
    padding: 16px;
    flex-basis: 50%;

    @include media(null, $bp-xmedium) {
      flex-basis: 100%;
    }

    img {
      width: 60px;
    }
  }

  .section-2 {
    flex-basis: 50%;
    background-size: cover;
    height: 100%;
    right: 0px;
    top: 0px;
    opacity: 1;
    width: 100%;
    background-position: center center;
    transition: opacity 1s ease-in-out 0s;

    button {
      position: relative;
      display: block;
      width: 100%;
      font-weight: 500;
      vertical-align: middle;
      top: 40%;
      text-align: center;
      box-sizing: border-box;
      white-space: nowrap;
      font-size: 18px;
      font-family: Roboto, sans-serif;
      cursor: pointer;
      color: rgb(251, 249, 238);
      text-decoration: none;
      border-style: solid;
      border-image: initial;
      border-width: 2px;
      padding: 12px 24px;
      border-radius: 50px;
      background: transparent;
      opacity: 0;
      border-color: rgb(251, 249, 238);
      transition: opacity 0.3s ease-in-out 0s;

      &:hover {
        opacity: 1;
      }
    }

    @include media(null, $bp-xmedium) {
      display: none;
    }

  }


  .card-icon {
    @include media(null, $bp-xmedium) {
      width: auto;
      margin-bottom: 8px;
    }
  }

  @media (min-width: 1000px) {
    .card-title {
      font-size: 24px;
      line-height: 30px;
    }
  }

  .card-title {
    color: inherit;
    font-size: 18px;
    font-family: Roboto, sans-serif;
    font-weight: 600;
    line-height: 24px;
    margin: 0px 0px 8px;
  }

  @media (min-width: 750px) {
    .card-desc {
      margin: 8px 0px;
    }
  }

  .card-desc {
    color: rgb(251, 249, 238);
    font-size: 16px;
    font-family: Roboto, sans-serif;
    font-weight: normal;
    line-height: 20px;
  }

}