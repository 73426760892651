@import '../../assets/scss/placeholder';
@import '../../assets/scss/variable';
@import '../../assets/scss/mixins';

@mixin form-container {
  padding: 10px 0;
  padding: 10px 0;
  justify-content: space-between;
  flex-basis: 45%;

  .input-label {
    display: block;
    color: rgb(53, 53, 48);
    margin-bottom: 4px;
    @extend %h6;
    font-weight: bold;
  }

  .input__control {
    margin-bottom: 16px;
    background-color: rgb(246, 246, 246);
    color: rgb(53, 53, 48);
    box-shadow: none;
    font-family: Roboto, sans-serif;
    box-sizing: border-box;
    @extend %h5;
    padding: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(223, 226, 231);
    border-image: initial;
    border-radius: 2px;
  }
}

.dashboard-container {
  .dashboard-container__title {
    @extend %h4;
    font-weight: normal;
    background-color: $green-theme-color;
    color: #fff;
    padding: 10px;
  }

  p {
    padding: 10px;
  }

  .dashboard-form {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: row;

    .full-width__input__container {
      @include form-container();
      padding: 10px 0;
      padding: 10px 0;
      justify-content: space-between;
      flex-basis: 100%;
    }

    .input__container {
      @include form-container()
    }
  }
}

.btn__container {
  width: 100%;
  margin: 15px 0px;

  .dashboard-full-btn {
    width: 100%;
  }

  .dashboard-form-submit {
    position: relative;
    display: block;
    font-weight: normal;
    text-align: center;
    box-sizing: border-box;
    white-space: nowrap;
    @extend %h5;
    font-family: Roboto, sans-serif;
    cursor: pointer;
    color: rgb(255, 255, 255);
    text-decoration: none;
    border-style: solid;
    border-image: initial;
    border-width: 0px;
    padding: 6px 12px;
    border-radius: 4px;
    background-color: $green-theme-color;
    border-color: transparent;
  }
}