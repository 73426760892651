@import './variable';
@import './loader';
@import './global';
@import './display';
@import './modal';
@import './auth';
@import './layout';
@import './form';
@import './mixins';
@import './placeholder';
@import './sidemenu.scss';
@import './buttons';

* {
  box-sizing: border-box;
}

html body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  top: 0px;
  left: 0px;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', sans-serif;
  font-weight: 400;
  color: #383838;
  background-color: #fafafa;
  opacity: 1;
}