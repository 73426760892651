@import '../../../assets/scss/placeholder';

// points, value, bookmark, favourits
.image {

  &__likes-value,
  &__points-value,
  &__comment-value,
  &__likes-bookmark {
    @include font(12, 400);
    color: $black-color;
    display: inline-block;
    padding: 0 10px 0 0;
    vertical-align: top;

    &::before {
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      content: '';
      display: inline-block;
      height: 17px;
      margin: 0 5px 0 0;
      padding-right: 2px;
      vertical-align: top;
      width: 17px;
    }
  }

  &__value {
    display: inline-block;
    min-width: 32px;
    text-align: left;
  }

  &__likes-points {
    text-align: center;
  }

  &__points-value {
    &::before {
      background-image: url('../../../assets/images/bookmark.svg');
    }
  }

  &__likes-value {
    cursor: pointer;

    &::before {
      background-image: url('../../../assets/images/favrt.svg');
    }

    &.flagged {
      &::before {
        background-image: url('../../../assets/images/favrt-marked.svg');
      }
    }
  }

  &__comment-value {
    cursor: pointer;

    &::before {
      background-image: url('../../../assets/images/comment.svg');
    }
  }

  &__likes-bookmark {
    cursor: pointer;
    padding: 0;

    &::before {
      background-image: url('../../../assets/images/bookmark.svg');
    }

    &.flagged {
      &::before {
        background-image: url('../../../assets/images/bookmark-marked.svg');
      }
    }
  }
}