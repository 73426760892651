@import '../../assets/scss/placeholder';
@import '../../assets/scss/variable';
@import '../../assets/scss/mixins';

.heroSection-container {
  margin-bottom: 50px;
  padding-top: 70px;

  &.homePageSection {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 66vh;
    position: relative;
    text-align: start;
  }

  @media(min-width: 750px) and (max-width:999px) {
    &.homePageSection {
      flex-direction: row;
      align-items: center;
    }
  }

  @media(min-width: 1000px) {
    &.homePageSection {
      padding-left: 0;
      padding-right: 0
    }
  }

  .bg-container {
    background-size: cover;
    height: 100%;
    position: absolute;
    right: 0px;
    top: 0px;
    opacity: 1;
    width: 100%;
    background-position: center center;
    transition: opacity 1s ease-in-out 0s;
  }

  .bg-container {
    &._1 {
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: cover;
      margin: 0;
      background-image: linear-gradient(rgba(0, 0, 0, 0.68) 0%, rgba(0, 0, 0, 0.71) 100%),
        url('../../assets/images/care1.jpg');
    }
  }

  .heroSection__content {
    position: relative;
    min-width: 300px;
    text-align: center;
    width: 60%;
    z-index: 1;

    .heading {
      color: rgb(255, 250, 209);
      @extend %h1;
      font-family: Roboto, sans-serif;
      font-weight: 600;
      margin: 0px 0px 8px;
      line-height: 45px;

      @include media(null, $bp-xmedium) {
        line-height: 55px;
      }
    }

    .subHeading {
      color: rgb(251, 249, 238);
      @extend %h3;
      font-family: Roboto, sans-serif;
      font-weight: bold;
      line-height: 28px;
      margin: 0px 0px 8px;

      @include media(null, $bp-xmedium) {
        line-height: 34px;
      }
    }
  }

  @media(max-width: 749px) {
    .heroSection__content {
      width: 80%;
      margin: 0 auto;
    }
  }

  @media(min-width: 750px) {
    .heroSection__content {
      width: 100%;
      text-align: center;
      margin: 0 auto;
    }
  }

  @media(min-width: 1000px) {
    .heroSection__content {
      width: 80%;
      text-align: center;
      margin: 0 auto;
    }
  }

  @media(min-width: 1200px) {
    .heroSection__content {
      width: 80%;
      text-align: center;
      margin: 0 auto;
    }
  }

}