.wrapper {
  margin: 0 auto;
  max-width: 380px;
  padding: 40px 0 30px;

  .message-error {
    margin-bottom: 10px;
    padding: 10px;
    font-size: 0.9rem;
    background-color: #ed6006;
  }

  .message-success {
    margin-bottom: 10px;
    padding: 10px;
    font-size: 0.9rem;
    background-color: #01bbd2;
  }

  .errorMsg {
    font-size: 0.9rem;
    color: #ed6006;
  }

  .form-label {
    margin-bottom: 20px;
    display: block;
  }

  .button {
    font-size: .75rem;
    font-weight: 800;
    background-color: #8cc53d;
    border: 0;
    color: #fff;
    cursor: pointer;
    letter-spacing: .25em;
    margin: 5px 0 15px;
    padding: 7px 10px;
    text-transform: uppercase;
    width: 100%;
  }
}

.global-btn-theme {
  position: relative;
  margin: 0 auto;
  display: block;
  width: 200px;
  font-weight: 500;
  text-align: center;
  box-sizing: border-box;
  white-space: nowrap;
  font-size: 18px;
  font-family: Roboto, sans-serif;
  cursor: pointer;
  color: rgb(255, 255, 255);
  text-decoration: none;
  border-style: solid;
  border-image: initial;
  border-width: 2px;
  padding: 12px 24px;
  border-radius: 50px;
  background: rgb(253, 120, 84);
  border-color: transparent;
}

input {
  font-size: 0.9rem;
  border: 1px solid #ccc;
  color: #000;
  display: block;
  outline: none;
  padding: 10px 15px;
  width: 100%;
}

.auth-options {
  display: flex;
  flex-direction: column;
  justify-content: left;
  margin: 0;
  padding: 0px;
  margin-top: 10px;

  &__item {
    text-align: center;
    display: block;
    font-family: $font-roboto;
    font-weight: 600;
    padding-bottom: 10px;

    &-forgetPass {
      color: black;
      font-family: $font-roboto-bold;
      font-size: 0.8rem;
      text-decoration: none;
    }

    &-login {
      color: black;
      font-family: $font-roboto-bold;
      font-size: 0.8rem;
      text-decoration: none;
    }

    &-register {
      color: black;
      font-family: $font-roboto-bold;
      font-size: 0.8rem;
      text-decoration: none;
    }
  }
}