@import '../../../assets/scss/variable';
@import '../../../assets//scss/mixins';

.features-trending {
  .trending-container {
    .home-header {
      &.left {
        float: left;
      }
      padding: 30px 50px 40px;
      clear: both;
      .underline {
        margin-top: 17px;
        margin-left: 40px;
        background-color: #99ca3c;
        height: 5px;
        border-radius: 2px;
        width: 110px;
        float: right;
      }
      h2 {
        margin-top: 8px;
        float: right;
        font-size: 22px;
        color: #363636;
        font-family: Montserrat;
        font-weight: 600;
        margin-bottom: 10px;
      }
    }
    .row {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 20px;
      .col {
        float: left;
        width: 50%;
        margin-left: auto;
        left: auto;
        right: auto;
      }
      .refer-earn {
        text-align: center;
        margin-top: 100px;
        .header {
          font-weight: 700;
          font-size: 34px;
          margin: 20px 0;
        }
        .search-btn {
          margin-top: 20px;
          border: none;
          width: 200px;
          border-radius: 20px;
          background-color: #9c3;
          color: #fff;
          height: 35px;
          line-height: 10px;
          font-family: Montserrat;
          font-weight: 700;
          font-size: 14px;
          display: block;
          margin: 30px auto;
        }
      }
      .features {
        .feature-icon {
          padding: 0 5px;
        }
        .feature-description {
          border-left: 2px solid #99ca3c;
          padding: 0 30px;
          h4 {
            font-weight: 700;
            font-size: 20px;
            margin: 20px 0;
          }
        }
        img {
          height: 46px;
          width: auto;
          margin-top: 15px;
        }
      }
    }
  }
}
