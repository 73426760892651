@import '../../assets/scss/placeholder';
@import '../../assets/scss/variable';
@import '../../assets/scss/mixins';

.section-block {
  background-position: center center;
  margin-top: 60px;
  padding-bottom: 30px;
}

@media (min-width: 1200px) {
  .section-inner-container {
    width: 1152px;
    margin: 0 auto;
  }
}

.section-inner-container {
  padding: 10px;
}

.nanny-container {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  margin-top: 30px;
  padding-bottom: 30px;
}

.grid-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.grid-item {
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  height: 270px;

  width: 250px;
  flex-basis: 23.5%;
  position: relative;
  box-sizing: border-box;
  padding-left: 0px;
  margin-bottom: 20px;

  @include media(null, $bp-xmedium) {
    flex-basis: 32.5%;
  }

  @include media(null, $bp-small) {
    flex-basis: 48%;
  }

  @include media(null, $bp-xsmall) {
    flex-basis: 48%;
  }

  @include media(null, $bp-medium) {
    flex-basis: 48%;
  }


  .grid-item-body {
    padding: 5px;
    height: 30px;
    height: 30%;
    @extend %h6;
    color: #353530;

    &__container {
      padding: 5px;

      p {
        @extend %h6;
        font-family: Roboto;
        line-height: 20px;

        span {
          @extend %h6;
        }

        .card-orange {
          margin-right: 5px;
          color: orange;
        }
      }

      .card-rating {
        .card-ratings {
          @extend %h6;
        }
      }
    }
  }

  .grid-item-footer {
    border-top: 1px solid #cccccc45;
    padding: 10px;
    height: 30px;
    height: 20%;
    @extend %h6;
    color: #353530;

    span {
      @extend %h6;
    }
  }

  .grid-item-star {
    display: inline-block;
    height: 26px;
    background-color: rgb(234, 229, 255);
    color: rgb(138, 110, 255);
    font-family: Roboto, sans-serif;
    line-height: 18px;
    @extend %h6;
    border-radius: 3px;
    padding: 4px 8px;
    position: absolute;
    top: 38%;
    right: 1%;
  }

  .grid-item-tag {
    display: inline-block;
    height: 26px;
    background-color: #ffffff;
    color: #505050;
    font-family: Roboto, sans-serif;
    border-radius: 3px;
    top: 38%;
    left: 10px;
    padding: 4px 4px;
    margin: 0px 8px 0px 0px;
    position: absolute;
    left: 2%;
    @extend %h5;

    span {
      @extend %h5;
    }
  }
}

.grid-row a {
  text-decoration: none;
}

.wrapping-link {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
  color: currentColor;
}

.grid-item-wrapper {
  box-sizing: initial;
  margin: 0;
  height: 50%;
  width: 100%;
  overflow: hidden;
  transition: padding 0.15s cubic-bezier(0.4, 0, 0.2, 1), margin 0.15s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;

  background-size: cover;
  background-position: center;
  background-image: url('../../assets/images/scooty.png');
}

.grid-item-container {
  height: 100%;
  width: 100%;
  position: relative;
}

.grid-image-top {
  height: 45%;
  width: 120%;
  background-size: cover;
  position: relative;
  background-position: 50% 50%;
  left: -10.5%;
  top: -4.5%;
  display: none;
}

.grid-image-top .centered {
  text-align: center;
  transform: translate(-50%, -50%);
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  top: 54.5%;
  left: 50%;
  width: 60%;
  height: 60%;
  background-position: center;
}



@media(max-width: 620px) {
  .col {
    clear: both;
    float: none;
    margin-left: auto;
    margin-right: auto;
    width: auto !important;
  }
}