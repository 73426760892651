@import '../../assets/scss/mixins';
@import '../../assets/scss/variable';
@import '../../assets/scss/placeholder';


.section-addon-host {
  background: center center / cover rgb(255, 255, 255);
  background-position: center center;

  .section-inner-container {
    position: relative;
    padding: 32px 16px;
    margin: 0px auto;

    .__container {
      display: flex;
      flex-direction: row;
      justify-content: center;

      @include media(null, $bp-medium) {
        display: inline;
      }

    }

    .addon-card {
      display: flex;
      margin: 10px;
      color: #fbf9ee;
      background: #353530;
      flex-basis: 50%;
      color: #fbf9ee;
      background: #353530;

      .addon-bg {
        background-size: cover;
        position: relative;
        display: flex;
        flex-basis: 50%;
        /* width: 100%; */
        align-items: center;
        justify-content: center;
        transition: box-shadow 0.2s ease 0s;
      }

      .addon-card__column {
        margin: 10px;
        flex-basis: 50%;
      }


      .addon-card-title {
        color: rgb(255, 250, 209);
        @extend %h4;
        font-family: Roboto, sans-serif;
        font-weight: 600;
        line-height: 24px;
        margin: 0px 0px 8px;

        @include media(null, $bp-medium) {
          line-height: 30px;
        }
      }


      .addon-card-subtitle {
        color: inherit;
        @extend %h5;
        font-family: Roboto, sans-serif;
        font-weight: normal;
        line-height: 20px;

        @include media($bp-medium, $bp-xlarge) {
          margin: 16px 0px;
        }
      }

      .addon-btn {
        position: relative;
        display: inline-block;
        width: 100%;
        font-weight: 500;
        text-align: center;
        vertical-align: middle;
        box-sizing: border-box;
        white-space: nowrap;
        @extend %h4;
        font-family: Roboto, sans-serif;
        cursor: pointer;
        color: rgb(251, 249, 238);
        text-decoration: none;
        border-style: solid;
        border-image: initial;
        border-width: 2px;
        padding: 10px 15px;
        border-radius: 50px;
        background: transparent;
        border-color: rgb(251, 249, 238);
      }
    }
  }
}