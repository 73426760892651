@import '../../assets/scss/mixins';
@import '../../assets/scss/variable';
@import '../../assets/scss/placeholder';


.search-box {
  padding: 32px;
  width: 100%;
  margin: 0 auto;

  &__wrapper {
    position: relative;
    transform: translate3d(0px, 0px, 0px);
  }
}

@media (min-width: 750px) {
  .search-box {
    width: unset;
  }
}

.homePage-SearchForm {
  color: #353530;
  background: #ffffff38;
  font-family: Roboto, sans-serif;
  position: relative;
  border-radius: 4px;
  display: flex;
  align-items: flex-end;
}

.homePage-SearchForm.container {
  color: #353530;
  background: #ffffff38;
  font-family: Roboto, sans-serif;
  position: relative;
  border-radius: 4px;
  display: flex;
  align-items: flex-end;
  padding-bottom: 10px;
  padding-top: 0px;
  padding-left: 0px;

  @include media(null, $bp-xmedium) {
    padding: 16px;
  }

  .__submitButtonGroup {

    .__btn {
      position: relative;
      display: inline-block;
      width: auto;
      font-weight: normal;
      text-align: center;
      vertical-align: middle;
      box-sizing: border-box;
      white-space: nowrap;
      font-size: 1em;
      font-family: Roboto, sans-serif;
      cursor: pointer;
      color: rgb(255, 255, 255);
      text-decoration: none;
      border-style: solid;
      border-image: initial;
      border-width: 0px;
      padding: 6px 12px;
      border-radius: 22px;
      background: rgb(253, 120, 84);
      border-color: transparent;
    }

    .__submitButton {
      padding: 12px 48px;
      border-radius: 50px;
    }

    @media (min-width: 750px) {
      .__submitButton {
        width: 100%;
        margin-top: 10px;
      }
    }

    @media (min-width: 320px) {
      .__submitButton {
        width: 100%;
        margin-top: 10px;
      }
    }
  }

  .__inputGroup.__searchInputGroup {
    margin-left: 16px;
    flex: 3;
    position: relative;

    .__searchInput {
      .__openSearchInputContainer {
        width: 100%;
        z-index: 5;
        min-width: 250px;

        .__input {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          background: #fff;
          border: 1px solid #dfe2e7;
          border-radius: 4px;
          padding: 12px 12px 12px 20px;
          width: 100%;
        }
      }
    }

    .__label {
      margin: 0 0 8px;
      font-weight: 600;
      white-space: nowrap;
      position: absolute;
      top: 0;
      left: 0;
    }

    @media (max-width: 999px) {
      .__label {
        display: none
      }
    }
  }
}

@media(max-width: 999px) {
  .homePage-SearchForm.container {
    flex-direction: column;
    width: 100%;
  }

  .sticky-outer-wrapper.active .homePage-SearchForm.container {
    padding: 16px 16px 16px 48px
  }
}

@media(max-width: 749px) {
  .homePage-SearchForm.container {
    border-radius: 0
  }
}

.homePage-SearchForm.container .__inputGroup {
  margin-left: 16px;
  flex: 2;
  position: relative;
}

@media(max-width: 999px) {
  .homePage-SearchForm.container .__inputGroup {
    width: 100%;
    margin-left: 0;
    padding-top: 0
  }
}

.container {
  margin-right: auto;
  margin-left: auto
}

@media(max-width: 749px) {
  .container {
    width: 100%;
    padding-left: 8px;
    padding-right: 8px
  }
}

@media(min-width: 750px) {
  .container {
    width: 100%;
    padding-right: 16px;
    padding-left: 16px
  }
}