@import '../../../assets/scss/mixins';
@import '../../../assets/scss/variable';
@import '../../../assets/scss/placeholder';

.header-container.--isTransparent {
  background: 0 0;
  color: #fff;
}

.header-mobile-container.--isTransparent {
  background: 0 0;
  color: #fff;
}

.header-mobile-container {
  width: 100%;
  position: fixed;
  display: none;
  z-index: 501;
  background: #fff;
  color: #8f8e87;
  align-items: center;
  justify-content: space-between;

  @include media(0, $bp-medium) {
    display: flex;
  }

  .__column {
    display: flex;
    align-items: center;
  }

  .__logo {
    background-image: url(../../../assets/images/logo.webp);
    background-repeat: no-repeat;
    background-size: contain;
    height: 60px;
    min-width: 80px;
    width: auto;
    padding: 2px 30px;
    background-position-y: center;
    display: inline-block;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    margin-left: -5px;
  }

  &.--isTransparent {
    .__desktopNavItems {
      display: flex;
      align-items: center;

      a {
        text-decoration: none;
        cursor: pointer;
        color: $white-color;
        @extend %h5;
        background-color: transparent;
      }

      &__img {
        display: block;
        width: 32px;
        height: 18px;
      }
    }

    .__navItem {
      color: currentColor;
      cursor: pointer;
      @extend %h5;

      &.--isHighlighted {
        @extend %h5;
        margin-right: 10px;
        border-radius: 22px;
        padding: 0 8px;
        border: 2px solid #fff;
        line-height: 32px;
        border-radius: 20px;
        text-transform: capitalize;
        background-color: transparent;
        padding: 0 32px;
        font-size: 15px;
        color: #fff;
        box-shadow: none;
        margin-right: 26px;
        color: #fff;
        padding: 8px 16px;
      }

      &__img {
        display: block;
        width: 32px;
        height: 18px;
      }
    }
  }

  .__desktopNavItems {
    display: flex;
    align-items: center;

    a {
      text-decoration: none;
      cursor: pointer;
      color: $green-theme-color;
      @extend %h5;
      background-color: transparent;
    }

    &__img {
      display: block;
      width: 32px;
      height: 18px;
    }
  }

  .__navItem {
    color: currentColor;
    padding: 0 8px;
    cursor: pointer;
    @extend %h5;

    &.--isHighlighted {
      @extend %h5;
      border-radius: 22px;
      padding: 0 8px;
      border: 2px solid #fff;
      line-height: 32px;
      border-radius: 20px;
      text-transform: capitalize;
      background-color: transparent;
      padding: 0 32px;
      font-size: 15px;
      color: #fff;
      box-shadow: none;
      margin-right: 26px;
      color: #fff;
      padding: 8px 16px;
    }

    &__img {
      display: block;
      width: 32px;
      height: 18px;
    }
  }
}

.header-container {
  width: 100%;
  position: fixed;
  z-index: 501;
  background: #fff;
  color: #8f8e87;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;

  @include media(0, $bp-medium) {
    display: none;
  }

  .__column {
    display: flex;
    align-items: center;
  }

  .__logo {
    background-image: url(../../../assets/images/logo.webp);
    background-repeat: no-repeat;
    background-size: contain;
    height: 60px;
    min-width: 120px;
    background-position-y: center;
    display: inline-block;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    margin-left: -5px;
  }
  .__desktopNavItems {
    display: flex;
    align-items: center;

    a {
      text-decoration: none;
      cursor: pointer;
      @extend %h5;
      margin-right: 10px;
      background-color: transparent;
    }

    .__navItem {
      color: $black-color;
      padding: 0 8px;
      cursor: pointer;
      @extend %h5;

      &.--isHighlighted {
        border-radius: 22px;
        padding: 0 8px;
        border: 2px solid $green-theme-color;
        border-radius: 20px;
        text-transform: capitalize;
        background-color: transparent;
        padding: 0 32px;
        font-size: 15px;
        color: $black-color;
        box-shadow: none;
        margin-right: 15px;
        padding: 8px 16px;
      }

      &__img {
        display: block;
        width: 32px;
        height: 18px;
      }
    }
  }

  &.--isTransparent {
    .__desktopNavItems {
      display: flex;
      align-items: center;

      a {
        text-decoration: none;
        cursor: pointer;
        color: $white-color;
        @extend %h5;
        background-color: transparent;
      }

      &__img {
        display: block;
        width: 32px;
        height: 18px;
      }
    }

    .__navItem {
      color: currentColor;
      padding: 0 8px;
      cursor: pointer;
      @extend %h5;

      &.--isHighlighted {
        @extend %h5;
        border-radius: 22px;
        padding: 0 8px;
        border: 2px solid #fff;
        border-radius: 20px;
        text-transform: capitalize;
        background-color: transparent;
        padding: 0 32px;
        font-size: 15px;
        color: #fff;
        box-shadow: none;
        margin-right: 15px;
        color: #fff;
        padding: 8px 16px;
      }

      &__img {
        display: block;
        width: 32px;
        height: 18px;
      }
    }
  }
}

.header-login {
  display: block;
  padding: 0;
  position: static;
}

.header {
  left: 0;
  padding: 20px 0 0;
  top: 0;
  width: 100%;
  z-index: 10;

  .page__container {
    margin: 0 auto;
    max-width: 1212px;
    padding: 0 10px;
  }

  .logo {
    display: flex;
    justify-content: center;
    padding: 20px 0 0;

    img {
      height: 200px;
    }
  }
}