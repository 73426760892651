@import '../../assets/scss/variable';
@import '../../assets/scss/mixins';
@import '../../assets/scss/placeholder';

.flex-grid {
  display: flex;
  justify-content: flex-start;
  text-align: center;

  .Onboarding-Container {
    padding: 1rem;
  }

  &.Onboarding {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .Onboarding-Icon {
    width: 100px;
    height: 100px;
  }

  .Onboarding-Title {
    font-family: Roboto, sans-serif;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 700;
    @extend %h4;
    color: #353530;
    text-align: center;
    margin: 8px auto;
  }

  .Onboarding-Text {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    color: #8f8e87;
  }

}