.slick-container {
  &.container {
    padding: 0px;
  }
  box-shadow: 2px 2px 2px 2px #aaaaaa;
  clear: both;
  padding: 30px;
  .bike-info {
    text-align: center;
    .h4 {
      margin-top: 80px;
      font-family: Roboto;
      font-size: 30px;
      font-weight: 600;
      color: black;
    }
    p {
      font-family: Roboto;
      font-size: 23px;
      font-weight: 600;
      color: black;
    }
  }
  .search-btn {
    margin-top: 20px;
    border: none;
    width: 200px;
    border-radius: 20px;
    background-color: #9c3;
    color: #fff;
    height: 35px;
    line-height: 10px;
    font-family: Montserrat;
    font-weight: 700;
    font-size: 14px;
    margin: 10px;
  }
  .row {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    clear: both;
    > .col-4 {
      padding: 30px 30px;
      float: left;
      width: 25%;
      margin-left: auto;
    }
    > .col-8 {
      padding: 30px 30px;
      float: left;
      width: 75%;
      margin-left: auto;
    }
  }
  .slick-next:before,
  .slick-prev:before {
    font-size: 20px;
    line-height: 1;
    opacity: 0.75;
    color: #8cc63e;
  }
}
