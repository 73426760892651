* {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  box-sizing: border-box;
  font-size: 16px;
}

img {
  max-width: 100%;
}
html, body {
  height: 100%;
  margin: 0;
}
body {
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: none;
  width: 100% !important;
  height: 100%;
  line-height: 1.6em;
}

body {
  display: flex;
  flex-direction: column;
}

.body-container {
  flex: 1 0 auto;
}
.padding-lr {
    padding-right: 15px;
    padding-left: 15px;
}
.container {
  margin-right: auto;
  margin-left: auto
}

@media(max-width: 749px) {
  .container {
      width:100%;
      padding-left: 8px;
      padding-right: 8px
  }
}

@media(min-width: 750px) {
  .container {
      width:90%;
      padding-right: 16px;
      padding-left: 16px
  }
}

@media(min-width: 1000px) {
  .container {
      width:968px
  }
}

@media(min-width: 1200px) {
  .container {
      width:1152px
  }
}
@media (min-width: 1024px) {
  .container.alt {
    max-width: 1200px;
  }
}
.page__container {
  margin: 0 auto;
  max-width: 1212px;
  padding: 0 10px;
}
.marginbottom-lg {
  margin-bottom: 30px!important;
}

.margintop-xlg {
  margin-top: 60px!important;
}

.not-found {
  display: block;
    margin: 0 auto;
    width: 400px;
    height: 100%;
    margin-top: 30px;
    text-align: center;
}