@import '../../../assets/scss/variable';

.subheader {
  background-color:$green-theme-color;
  margin-top: 55px;
  border-top: 1px solid #f6f6f6;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .07);

  .subheader-nav {
    width: 100%;

    li {
      display: inline-block;
      padding: 12px;
      cursor: pointer;

      a {
        font-size: 14px;
        text-decoration: none;
        color: #ffffff;

        &.active {
          color: #ffffff;
        }
      }
    }
  }
}