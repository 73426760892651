@import '../../../assets/scss/variable';
@import '../../../assets/scss/mixins';


.side-notification {
  @include media(null, $bp-xmedium) {
    height: calc(100% - 57px);
    top: 50px;
  }

  @include media(null, $bp-medium) {
    right: -320px;
    width: 320px;
  }

  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border-top: 1px solid #ccc;

  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  background-color: $white-color;
  height: calc(100% - 58px);
  overflow-y: auto;
  padding: 0 40px 20px;
  position: fixed;
  right: -470px;
  top: 50px;
  transition: all .5s ease;
  width: 320px;
  z-index: 2;

  &.notify {
    right: 0;

  }

  &_title {
    padding-top: 20px;
    text-align: left;
  }

  &_list {
    @include padding-margin;
    @include clear-list;
  }

  &_item {
    cursor: pointer;
    padding: 25px 0;
    text-align: center;

    &:not(:last-child) {
      border-bottom: 1px solid $black-color;
    }
  }

  &_link {
    color: $black-color;
    cursor: pointer;

    &.unread {
      color: $black-color;
    }
  }

  .see-more {
    span {
      @include media(null, $bp-medium) {
        min-width: 100%;
      }
    }
  }
}