@import '../../../assets/scss/variable';
@import '../../../assets//scss/mixins';
@import '../../../assets//scss/placeholder';

.features-trending {
  .hero-container {

    .hero-header {
      &.right {
        float: right;
      }
      &.left {
        float: left;
      }

      float: right;
      .underline {
        margin-top: 17px;
        margin-left: 40px;
        background-color: #99ca3c;
        height: 5px;
        border-radius: 2px;
        width: 110px;
        float: right;
      }
      h2 {
        margin-top: 8px;
        float: right;
        font-size: 22px;
        color: #363636;
        font-family: Montserrat;
        font-weight: 600;
        margin-bottom: 10px;
      }
    }
    @include media($bp-xxlarge) {
        .hero-header {
          padding: 30px 50px 40px;
        }
      }
    @include media(null, $bp-xmedium) {
        .hero-header {
          padding: 30px 50px 40px;
        }
      }
      @include media(null, $bp-medium) {
        .hero-header {
          display: none;
          padding: 20px 30px 20px;
        }
      }
      @include media(null, $bp-small) {
        .hero-header {
          display: none;
          padding: 10px;
        }
      }
    > .row {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 20px;
      clear: both;
      > .col {
        padding: 10px 10px;
        float: left;
        width: 50%;
        margin-left: auto;
        left: auto;
        right: auto;
      }
      @include media(null, $bp-medium) {
      > .col {
        padding: 0px 30px;
        float: left;
        width: 100%;
        margin-left: auto;
        left: auto;
        right: auto;
      }
  }

      .refer-earn {
        margin-top: 50px;
        text-align: center;
        .header {
          line-height: 1.5;
          font-weight: 700;
          font-size: 34px;
          margin: 20px 0;
        }
        .button-div {
          display: flex;
          justify-content: center;
          .search-btn {
            margin-top: 20px;
            border: none;
            width: 200px;
            border-radius: 20px;
            background-color: #9c3;
            color: #fff;
            height: 35px;
            line-height: 10px;
            font-family: Montserrat;
            font-weight: 700;
            font-size: 14px;
            margin: 10px;
          }
        }
      }
      @include media(null, $bp-medium) {
        .refer-earn {
            margin-top: 0px;
        }
      }
      .features {
        > .row {
          display: flex;
          justify-content: center;
          margin-bottom: 40px;
          &.col {
            padding: 10px;
            box-sizing: border-box;
            padding: 0 0.75rem;
            min-height: 1px;
            float: left;
            width: 50%;
            margin-left: auto;
            left: auto;
            right: auto;
          }
        }
        .feature-icon {
          padding: 0 5px;
        }
        .feature-description {
          border-left: 2px solid #99ca3c;
          padding: 0 30px;
          h4 {
            @extend %h4;
            font-weight: 700;
            margin: 20px 0;
          }
        }
        img {
          height: 46px;
          width: auto;
          margin-top: 15px;
        }
      }
    }
  }
}
